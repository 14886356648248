<template>
  <div
    v-shortkey="['shift', 'esc']"
    class="footer--outer"
    @shortkey="loginOrToggleUI"
  >
    <BasePageSection
      :class="['footer', 'py-0']"
      bg-color="#454242"
      title=""
      :pa-0="['xs', 'sm'].includes($mq)"
      full-bleed-bg
    >
      <VLayout>
        <VContainer class="pa-0" fluid grid-list-md>
          <VLayout justify-center align-space-around row wrap fill-height>
            <WithLocationSummaries v-slot="{ locations }">
              <VContainer class="locations" fluid grid-list-lg>
                <VLayout justify-left align-space-around row wrap fill-height>
                  <VFlex
                    v-for="(location, i) of locations"
                    :key="`location-${i}`"
                    grow
                    md3
                    sm6
                    xs12
                  >
                    <FooterLocation :location="location" />
                  </VFlex>
                  <VSpacer />
                  <VFlex
                    :class="[
                      'right-col text-md-right text-sm-left',
                      { 'align-self-end': !['lg', 'xl'].includes($mq) },
                    ]"
                    md3
                    sm6
                    xs12
                  >
                    <BaseButtonSendMessage class="ml-0" color="bubs-purple" />
                  </VFlex>
                </VLayout>
              </VContainer>
            </WithLocationSummaries>
            <VContainer fluid grid-list-md>
              <VLayout justify-left align-space-around row wrap fill-height>
                <VFlex
                  text-xs-center
                  text-md-left
                  xs12
                  sm12
                  md4
                  white--text
                  order-xs2
                  :pt-2="['xs', 'sm'].includes($mq)"
                >
                  <span
                    >&copy; {{ new Date().getFullYear() }}
                    {{ getLegalName }}</span
                  >
                </VFlex>
                <VSpacer />
                <VFlex
                  :class="['text-md-right']"
                  grow
                  md8
                  order-sm2
                  text-md-left
                  text-xs-center
                  xs12
                >
                  <BaseThemeComponent name="FooterMenu" />
                </VFlex>
              </VLayout>
            </VContainer>
          </VLayout>
        </VContainer>
      </VLayout>
      <BaseRestoByline link-color="#CA6FDA" />
    </BasePageSection>
    <BaseModalsAndSnackbars />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FooterLocation from '@components/FooterLocation'

export default {
  name: 'BubsFooter',
  components: {
    FooterLocation,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('site', ['getLegalName']),
  },
  methods: {
    ...mapActions('auth', ['loginOrToggleUI']),
  }, // methods
}
</script>

<style lang="scss">
.address {
  white-space: pre;
}
.footer .section-content > .container {
  width: 100%;
  max-width: 1440px;
}
.footer-contact-btn {
  max-width: 100%;
}
</style>
